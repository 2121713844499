exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-business-content-index-tsx": () => import("./../../../src/pages/businessContent/index.tsx" /* webpackChunkName: "component---src-pages-business-content-index-tsx" */),
  "component---src-pages-contact-complete-index-tsx": () => import("./../../../src/pages/contactComplete/index.tsx" /* webpackChunkName: "component---src-pages-contact-complete-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-[id]-tsx": () => import("./../../../src/pages/news/[id].tsx" /* webpackChunkName: "component---src-pages-news-[id]-tsx" */),
  "component---src-pages-news-index-tsx": () => import("./../../../src/pages/news/index.tsx" /* webpackChunkName: "component---src-pages-news-index-tsx" */),
  "component---src-pages-office-introduction-index-tsx": () => import("./../../../src/pages/officeIntroduction/index.tsx" /* webpackChunkName: "component---src-pages-office-introduction-index-tsx" */)
}

